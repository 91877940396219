$primary:       #1A202C;
$secondary:     #6c757d;
$success:       #2EDB4B;
$info:          #34AFF7;
$warning:       #FFD43A;
$danger:        #FF5252;
$dark:          #343a40;

$theme-colors: (
  "primary": $primary,
  'secondary': $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  'dark': $dark
);

$body-bg: #fff;
$nav-link-color: #717D96;
$pagination-border-radius: 10px !default;
$pagination-border-color: transparent !default;
$pagination-disabled-border-color: transparent !default;
$pagination-hover-border-color: transparent !default;
$pagination-disabled-bg: transparent !default;
$pagination-hover-bg: #E2E7F0;

.navbar-nav {
  margin-right: 32px;
  .active {
   color: darken($nav-link-color, 20%) !important;
  }
  .nav-link {
    color: $nav-link-color;
    font-size: 14px;
    font-weight: 700;
    &:hover {
      color: darken($nav-link-color, 20%) !important;
    }
  }
}

.profile-info {
  font-size: 12px;
  .strong {
    font-weight: 700;
  }
  .small {
    font-size: 11px;
  }
  p {
    margin-bottom: 0;
  }
}

// .dropdown-toggle {
//   border: none !important;
//   &:after {
//     display: none !important;
//   }
// }

.no-caret::after {
  display: none !important;
}

.no-caret .dropdown-toggle::after {
  display: none !important;
}

.contact-tabs {
  border: 1px solid #000 !important;
  .nav-item{
    .nav-link {
      color: #000 !important;
      font-weight: 700;
      margin-bottom: 0px !important;
      border-radius: 0px !important;
      border-color: #000 !important;
      &.active {
        background-color: #EDF0F7;
      }
      &.disabled {
        background-color: #EDF0F7;
        color: darken($color: #EDF0F7, $amount: 5%)  !important;
      }
    }
  }
}

.modal-header .btn-close {
  opacity: 1;
}

.form-control.is-search {
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9Ikljb24iPgo8cGF0aCBpZD0iU2hhcGUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNCAxMUM0IDcuMTM0MDEgNy4xMzQwMSA0IDExIDRDMTQuODY2IDQgMTggNy4xMzQwMSAxOCAxMUMxOCAxMi44ODU4IDE3LjI1NDMgMTQuNTk3NCAxNi4wNDE3IDE1Ljg1NjFDMTYuMDA3MyAxNS44ODI1IDE1Ljk3NDMgMTUuOTExNCAxNS45NDI4IDE1Ljk0MjlDMTUuOTExMyAxNS45NzQ0IDE1Ljg4MjQgMTYuMDA3NCAxNS44NTYgMTYuMDQxOEMxNC41OTczIDE3LjI1NDMgMTIuODg1NyAxOCAxMSAxOEM3LjEzNDAxIDE4IDQgMTQuODY2IDQgMTFaTTE2LjYxNzYgMTguMDMxOUMxNS4wNzggMTkuMjYzNSAxMy4xMjUgMjAgMTEgMjBDNi4wMjk0NCAyMCAyIDE1Ljk3MDYgMiAxMUMyIDYuMDI5NDQgNi4wMjk0NCAyIDExIDJDMTUuOTcwNiAyIDIwIDYuMDI5NDQgMjAgMTFDMjAgMTMuMTI1IDE5LjI2MzUgMTUuMDc4MSAxOC4wMzE5IDE2LjYxNzdMMjEuNzA3IDIwLjI5MjlDMjIuMDk3NSAyMC42ODM0IDIyLjA5NzUgMjEuMzE2NiAyMS43MDcgMjEuNzA3MUMyMS4zMTY1IDIyLjA5NzYgMjAuNjgzMyAyMi4wOTc2IDIwLjI5MjggMjEuNzA3MUwxNi42MTc2IDE4LjAzMTlaIiBmaWxsPSIjMkQzNjQ4Ii8+CjwvZz4KPC9zdmc+Cg==");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}



.search-wrapper {
  border: 2px solid #CBD2E0;
  border-radius: 8px;
  padding: 0px;

  .search-results {
    border-top: 2px solid #CBD2E0 !important;
    padding: 10px;
    max-height: 400px;
    overflow-y: scroll;
    .list-group-item {
      border: none !important;
      padding: 0px 8px;
    }
  }

  .form-control {
    border: none !important;
    border-radius: 8 !important;
    &:focus {
      border: none !important;
      box-shadow: none;
    }
  }
}


.custom-accordion {
  .accordion-button::after {display: none;}
  .accordion-button::before {
    flex-shrink: 0;
      width: var(--bs-accordion-btn-icon-width);
      height: var(--bs-accordion-btn-icon-width);
      margin-right: 10px;
      content: "";
      background-image: var(--bs-accordion-btn-icon);
      background-repeat: no-repeat;
      background-size: var(--bs-accordion-btn-icon-width);
      transition: var(--bs-accordion-btn-icon-transition);
  }

  .accordion-button:not(.collapsed)::before {
    background-image: var(--bs-accordion-btn-active-icon);
    transform: var(--bs-accordion-btn-icon-transform);
  }

  .accordion-button:not(.collapsed) {
    background-color: #E2E7F0;
  }

  .accordion-item {
    background-color: #F7F9FC;
  }
  .accordion-button {
    background-color: #E2E7F0;
  }
  .list-group-item {
    padding: 8px 0;
    border: none;
    background: none;
  }
}

.upcoming-meeting {
  .accordion-body {
    padding: 10px;
  }
}

.companies-nav {
  .navbar-nav {
    .nav-link {
      border-bottom: 2px solid transparent;
      padding-right: 0px !important;
      padding-left: 0px !important;
      margin-right: 24px;

      &:hover, &.active {
        border-bottom: 2px solid #2D3648;
      }
    } 
  }
}

.input-group > .form-control:not(:focus).is-valid {
  z-index: 0 !important;
}

.input-group > .form-control:not(:focus).is-invalid {
  z-index: 0 !important;
}

.address-field {
  .invalid-feedback {
    display: block
  }
}

.validation-field {
  .invalid-feedback {
    display: block
  }

  .warning-feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: var(--bs-warning); //--bs-warning
  }
}

.react-datepicker-wrapper {
  width: 100%
}

.kanban-list-group {
  .list-group-item {
    border: none
  }
}

@import '~bootstrap/scss/bootstrap.scss';
